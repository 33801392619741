/*
 * @Author: 
 * @Date: 2022-05-05 09:52:52
 * @LastEditors: 
 * @LastEditTime: 2022-06-29 10:04:54
 * @FilePath: \BigData_Upgrades\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/bd'
import '@/components/export'
// 全局引入旁庞门正道标题体
import "@/assets/css/font.css"
import "@/assets/css/complexFont.css"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
